import { Component, ViewChild } from '@angular/core'
import { HeaderDataEnum } from './enums/header-data-enum'
import { QMenuComponent } from '@dev/qtheme-lib'
import { DrawerMode } from '@progress/kendo-angular-layout'
import { NavigationEnd, NavigationExtras, Router } from '@angular/router'
import { Observable, filter } from 'rxjs'
import { CommonService } from '@app/services'
import { CommonState, ResetStateData } from './store'
import { Select, Store } from '@ngxs/store'
import { Roles } from './enums/roles'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  @ViewChild('qMenu') QMenu!: QMenuComponent
  mode: DrawerMode = 'push'

  isMenuOverlay = false
  isAdmin = false

  logoImage = 'assets/images/logo/logo.png'
  iconlogoImage = 'assets/images/logo/logo-mob.png'
  profileImage = 'assets/images/user.png'
  downarrowImage = 'assets/images/arrow.svg'
  currentUserDetails: any
  @Select(CommonState.menu)
  menuList$: Observable<Array<any>> | undefined

  @Select(CommonState.accountListPermission)
  accountListPermission$: Observable<Array<any>> | undefined

  menuList: any[] = []
  isQuickAddVisible: boolean = true
  isTopIconVisible: boolean = true

  headerDataList: Array<{ text: string; value: number; route?: string }> = [
    {
      text: HeaderDataEnum[HeaderDataEnum['Profile']],
      value: HeaderDataEnum['Profile'],
      route: '/create-account',
    },
    {
      text: HeaderDataEnum[HeaderDataEnum['Account List']],
      value: HeaderDataEnum['Account List'],
      route: '/account-list',
    },
    {
      text: HeaderDataEnum[HeaderDataEnum['Manage Devices']],
      value: HeaderDataEnum['Manage Devices'],
      route: '/manage-device',
    },
    {
      text: HeaderDataEnum[HeaderDataEnum['Change Password']],
      value: HeaderDataEnum['Change Password'],
      route: '/change-password',
    },
    {
      text: HeaderDataEnum[HeaderDataEnum['Log out']],
      value: HeaderDataEnum['Log out'],
    },
  ]

  constructor(
    private router: Router,
    public commonService: CommonService,
    public store: Store,
  ) {
    if (
      localStorage.getItem('loginToken') &&
      localStorage.getItem('menuList')
    ) {
      let menuList: any = JSON.parse(localStorage.getItem('menuList') ?? '[]')
      this.menuList = menuList.length > 0 ? menuList : []
    }
    this.currentUserDetails = this.commonService.getLoggedInUserDetails()

    if (+this.currentUserDetails.RoleId !== Roles.SuperAdmin) {
      this.headerDataList = this.headerDataList.filter(
        (item) => item.value !== HeaderDataEnum['Manage Devices'],
      )
    }

    this.accountListPermission$?.subscribe((data) => {
      if (!data.some((e) => e.visible)) {
        this.headerDataList = this.headerDataList.filter(
          (item) => item.value !== HeaderDataEnum['Account List'],
        )
      }
    })

    if (
      +this.currentUserDetails.RoleId === Roles.SuperAdmin ||
      +this.currentUserDetails.RoleId === Roles.Admin
    ) {
      this.headerDataList = this.headerDataList.filter(
        (item) => item.value !== HeaderDataEnum.Profile,
      )
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setHeaderText()
      })
  }

  changeOfRoutes() {
    this.setHeaderDropDownSelection()
    this.commonService.changeOfRoutes()
  }

  setHeaderDropDownSelection(): void {
    let urlIndex = ''
    if (this.router.url.indexOf(';') > 0) {
      urlIndex = ';'
    }
    if (this.router.url.indexOf('?') > 0) {
      urlIndex = '?'
    }
    const currentUrl =
      this.router.url.indexOf(urlIndex) > 0
        ? this.router.url.split(urlIndex)[0]
        : this.router.url

    const headerData = this.headerDataList.filter((x) => x.route === currentUrl)
    if (this.QMenu !== undefined && this.QMenu !== null) {
      this.QMenu.selectedItem = null
      if (headerData.length > 0) {
        this.QMenu.selectedItem = headerData[0]
      }
    }
  }

  isLoggedIn() {
    return this.commonService.isLoggedIn()
  }

  ngAfterViewInit(): void {
    this.setHeaderText()
  }

  ngOnInit(): void {
    this.setMenuMode()
    window.addEventListener('resize', () => {
      this.setMenuMode()
    })
  }

  setMenuMode(): void {
    this.isMenuOverlay = window.outerWidth < 1024
    if (this.isMenuOverlay) {
      this.mode = 'overlay'
    } else {
      this.mode = 'push'
    }
  }

  findSelectedItemByPath(items: any[], path: string): any {
    items.forEach((item) => (item.selected = false))
    for (const item of items) {
      if (item.path === path) {
        return item
      }
      if (item.children) {
        const childItem = this.findSelectedItemByPath(item.children, path)
        if (childItem) {
          return childItem
        }
      }
    }
    return null
  }

  onHeaderClick(event: any) {
    switch (event.value) {
      case HeaderDataEnum['Account List']:
        this.router.navigateByUrl('/account-list')
        break
      case HeaderDataEnum['Setting']:
        this.router.navigateByUrl('/threshold-settings')
        break
      case HeaderDataEnum['Notification']:
        this.router.navigateByUrl('/sent-notification')
        break
      case HeaderDataEnum['Log In']:
        localStorage.removeItem('loginToken')
        this.router.navigateByUrl('/login')
        break
      case HeaderDataEnum['Log out']:
        localStorage.removeItem('refreshLoginToken')
        localStorage.removeItem('loginToken')
        localStorage.removeItem('userDetails')
        localStorage.removeItem('menuList'), this.router.navigateByUrl('/login')
        this.store.dispatch(new ResetStateData()).subscribe()
        break
      case HeaderDataEnum['Manage Devices']:
        this.router.navigateByUrl('/manage-device')
        break
      case HeaderDataEnum['Change Password']:
        this.router.navigateByUrl('/change-password')
        break
      case HeaderDataEnum['Profile']:
        this.goToProfileDetail(
          this.currentUserDetails.EmailId,
          this.currentUserDetails.MobileNumber,
        )
        break
      default:
        break
    }
  }

  onNotificationClick(event: any) {
    this.router.navigateByUrl('/sent-notification')
  }

  onSettingClick(event: any) {
    this.router.navigateByUrl('/threshold-settings')
  }

  onDeviceClick(event: any) {
    this.router.navigateByUrl('/device')
  }

  goToProfileDetail(emailId?: any, mobileNumber?: any): void {
    let params = {
      isAddMode: false,
      emailId: btoa(emailId),
      mobileNumber: btoa(mobileNumber),
      isProfile: true,
    }

    let navigationExtras: NavigationExtras = {
      queryParams: params,
    }

    this.router.navigate(['create-account'], navigationExtras)
  }

  setHeaderText(): void {
    const currentUrl = this.router.url.split(';')[0]
    const selectedItem = this.findSelectedItemByPath(
      this.menuList,
      currentUrl !== '/add-Device' ? this.router.url : '/device',
    )

    if (selectedItem) {
      selectedItem.selected = true
      this.QMenu.headerText = selectedItem.headerText
      this.commonService.headerText = selectedItem.headerText
      this.QMenu.tempHeaderText = selectedItem.headerText
      localStorage.setItem('menuList', JSON.stringify(this.menuList))
    }

    if (currentUrl === '/manage-device') {
      this.QMenu.headerText = 'Manage Devices'
      this.commonService.headerText = 'Manage Devices'
      this.QMenu.tempHeaderText = 'Manage Devices'
    } else if (currentUrl === '/account-list') {
      this.QMenu.headerText = 'Accounts'
      this.commonService.headerText = 'Accounts'
      this.QMenu.tempHeaderText = 'Accounts'
    } else if (currentUrl === '/change-password') {
      this.QMenu.headerText = 'Change Password'
      this.commonService.headerText = 'Change Password'
      this.QMenu.tempHeaderText = 'Change Password'
    } else if (currentUrl === '/access-denied') {
      this.QMenu.headerText = 'Access Denied'
      this.commonService.headerText = 'Access Denied'
      this.QMenu.tempHeaderText = 'Access Denied'
    }
  }

  getIsLogged(): boolean {
    return localStorage.getItem('loginToken') ? true : false
  }
}
