import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { SharedModule } from '../shared/shared.module'
import { QThemeLibModule } from '@dev/qtheme-lib'
import { DrawerModule, LayoutModule } from '@progress/kendo-angular-layout'
import { SVGIconModule } from '@progress/kendo-angular-icons'
import { ButtonsModule } from '@progress/kendo-angular-buttons'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  DeviceModule,
  DashboardModule,
  DataReportModule,
  MeterDataModule,
  AnalyticsModule,
  GisInfoModule,
  SettingsModule,
  SettingHistoryModule,
  AccountModule,
  SystemBatteryModule,
  SoftwareModule,
  OfficeSettingModule,
  DownloadModule,
  DashboardDtrModule,
  SsptrrModule,
  SSLDDashboardModule,
  RoleModule,
  DtrPredictionAnalysisModule,
  PagePermissionModule,
  AlertModule,
  FeederModule,
  AccessDeniedModule,
  ColumnPermissionModule,
  ComplaintsModule,
  SubstationModule,
  ModuleHistoryModule,
  DeviceHistoryModule,
} from '@app/components'
import {
  AccountService,
  AlertSettingsService,
  AnalyticsService,
  CAIDIService,
  CommonService,
  DTRDataService,
  DashboardService,
  DashboarddtrService,
  DataReportService,
  DeviceService,
  MeterDataService,
  DtrpredictionService,
  OfficeSettingsService,
  OfficeUseChangesService,
  OverloadService,
  PFService,
  PagePermissionService,
  RoleService,
  SSReportService,
  SSSLDDashboardService,
  SentNotificationService,
  SettingHistoryService,
  SoftwareService,
  SystemBatteryService,
  ThresholdSettingsService,
  GisInfoService,
  FeederService,
  UserActivationService,
  ColumnPermissionService,
  GoogleMapsLoaderService,
  AssignHierarchicalStructureService,
  ComplaintsService,
  SubstationService,
  ModuleHistoryService,
  DeviceHistoryService,
} from '@app/services'
import { NgxPhotoEditorModule } from 'ngx-photo-editor'
import { NgxsModule } from '@ngxs/store'
import {
  DeviceState,
  AnalyticsState,
  AlertSettingsState,
  ThresholdSettingsState,
  SoftwareState,
  AccountState,
  OfficeSettingsState,
  SentNotificationState,
  OverloadState,
  PFState,
  CAIDIState,
  OfficeUseChangesState,
  SSReportState,
  SettingHistoryState,
  CommonState,
  SSSLDDashboardState,
  SystemBatteryState,
  RoleState,
  DashboardState,
  PagePermissionState,
  DashboardDtrState,
  DTRDataState,
  DataReportState,
  MeterDataState,
  DtrpredictionState,
  GisInfoState,
  FeederState,
  UserActivationState,
  ColumnPermissionState,
  AssignHierarchicalStructureState,
  ComplaintsState,
  SubstationState,
  ModuleHistoryState,
  DeviceHistoryState,
} from '@app/store'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import 'hammerjs'
import { CustomHttpInterceptor } from './interceptor'
import { JWT_OPTIONS, JwtHelperService, JwtModule } from '@auth0/angular-jwt'
import { AuthGuard } from './auth-guards'
import { ToastrModule } from 'ngx-toastr'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { NgxSpinnerModule } from 'ngx-spinner'
import { DatePipe, DecimalPipe } from '@angular/common'

export function initializeApp(googleMapsLoader: GoogleMapsLoaderService) {
  return (): Promise<void> => googleMapsLoader.load()
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    JwtModule,
    HttpClientModule,
    AccountModule,
    SharedModule,
    QThemeLibModule,
    LayoutModule,
    DrawerModule,
    SVGIconModule,
    ButtonsModule,
    NgxPhotoEditorModule,
    DashboardModule,
    DeviceModule,
    AnalyticsModule,
    SsptrrModule,
    ColumnPermissionModule,
    ModuleHistoryModule,
    DeviceHistoryModule,
    NgxsModule.forRoot([
      DeviceState,
      AnalyticsState,
      AlertSettingsState,
      ThresholdSettingsState,
      SoftwareState,
      AccountState,
      OfficeSettingsState,
      PFState,
      OverloadState,
      SentNotificationState,
      CAIDIState,
      OfficeUseChangesState,
      SSReportState,
      SettingHistoryState,
      SSSLDDashboardState,
      CommonState,
      SystemBatteryState,
      RoleState,
      DashboardState,
      PagePermissionState,
      DashboardDtrState,
      DTRDataState,
      DataReportState,
      MeterDataState,
      DtrpredictionState,
      GisInfoState,
      FeederState,
      UserActivationState,
      ColumnPermissionState,
      AssignHierarchicalStructureState,
      ComplaintsState,
      SubstationState,
      ModuleHistoryState,
      DeviceHistoryState,
    ]),
    NgxsStoragePluginModule.forRoot({
      key: ['common', 'device'],
    }),
    GisInfoModule,
    DataReportModule,
    MeterDataModule,
    SettingsModule,
    SettingHistoryModule,
    SystemBatteryModule,
    SoftwareModule,
    OfficeSettingModule,
    DownloadModule,
    DashboardDtrModule,
    SSLDDashboardModule,
    RoleModule,
    AlertModule,
    DtrPredictionAnalysisModule,
    PagePermissionModule,
    FeederModule,
    BrowserAnimationsModule,
    // required for toastr animations
    ToastrModule.forRoot({
      // your toastr configuration options
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    AccessDeniedModule,
  ],
  exports: [
    JwtModule,
    NgxPhotoEditorModule,
    SharedModule,
    DeviceModule,
    DashboardModule,
    AnalyticsModule,
    GisInfoModule,
    DataReportModule,
    MeterDataModule,
    SettingsModule,
    AccountModule,
    SettingHistoryModule,
    SystemBatteryModule,
    OfficeSettingModule,
    SsptrrModule,
    SSLDDashboardModule,
    RoleModule,
    DtrPredictionAnalysisModule,
    PagePermissionModule,
    FeederModule,
    ColumnPermissionModule,
    ComplaintsModule,
    SubstationModule,
    ModuleHistoryModule,
    DeviceHistoryModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    CommonService,
    AuthGuard,
    DeviceService,
    AnalyticsService,
    AlertSettingsService,
    ThresholdSettingsService,
    SoftwareService,
    AccountService,
    OfficeSettingsService,
    PFService,
    OverloadService,
    SentNotificationService,
    CAIDIService,
    OfficeUseChangesService,
    SSReportService,
    SettingHistoryService,
    SSSLDDashboardService,
    SystemBatteryService,
    RoleService,
    DashboardService,
    PagePermissionService,
    DashboarddtrService,
    DTRDataService,
    DataReportService,
    DecimalPipe,
    DatePipe,
    MeterDataService,
    DtrpredictionService,
    GisInfoService,
    FeederService,
    UserActivationService,
    ColumnPermissionService,
    AssignHierarchicalStructureService,
    ComplaintsService,
    SubstationService,
    ModuleHistoryService,
    DeviceHistoryService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [GoogleMapsLoaderService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
