<div class="container-wrapper">
  <ng-container *ngIf="getIsLogged(); else loginTemplate">
    <lib-q-menu
      #qMenu
      [mode]="mode"
      [logoImage]="logoImage"
      [iconlogoImage]="iconlogoImage"
      [profileImage]="profileImage"
      [headerData]="headerDataList"
      [items]="menuList"
      [downarrowImage]="downarrowImage"
      (triggerAdminClick)="onHeaderClick($event)"
      (triggerSettingClick)="onSettingClick($event)"
      (triggerNotificationClick)="onNotificationClick($event)"
      (triggerDeviceClick)="onDeviceClick($event)"
      [isTopIconVisible]="isTopIconVisible"
      [isQuickAddVisible]="isQuickAddVisible"
      [currentUser]="currentUserDetails.FirstName"
    >
      <router-outlet (activate)="changeOfRoutes()"></router-outlet>
    </lib-q-menu>
  </ng-container>
  <ng-template #loginTemplate>
    <router-outlet></router-outlet>
  </ng-template>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-triangle-path"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
